import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as Actions from './store/actions';
import { SnackbarProvider } from 'notistack';

import { firebaseImpl } from './firebaseUtils';
import FirebaseService from './services/firebaseService';

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import lightTheme from './themeLight';
import darkTheme from './themeDark';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import WifiIcon from '@material-ui/icons/Wifi';
import WarningIcon from '@material-ui/icons/Warning';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudOffIcon from '@material-ui/icons/CloudOff';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import ContactsIcon from '@material-ui/icons/Contacts';

import Front from "./components/Front";
import Back from "./components/Back";
import Auth from './components/Auth';

import { Detector } from "react-detect-offline";

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';


//import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import PicLogo from './assets/logo-exatronic-white.svg'
import BTSLogo from './assets/bts-logo.png'

import Badge from '@material-ui/core/Badge';
import StorageIcon from '@material-ui/icons/Storage';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';

import Drawer from '@material-ui/core/Drawer'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import sagiperVideoScreensaver from "./assets/bts-intro.mp4";

import ReactPlayer from 'react-player/lib/players/FilePlayer'
import IdleMonitor from 'react-simple-idle-monitor';

import "./App.css";

import Loader from './components/Loader';

import { db } from './api';
import { V, COUCH_URL, IDLE_TIME, HEARTBEAT_DB_TIME, API_ENDPOINT } from "./constants";

import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';

const useStyles = makeStyles(theme => ({

  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  drawerRoot: {
    width: '250px'
  },

  topBar: {
    background: 'linear-gradient(45deg, #051f4e 30%, #fff 90%)',
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  videoContainer: {
    //background: theme.palette.background.default,
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 9999999999
  },

  videoInner: {
    minWidth: '100%',
    minHeight: '100%',
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(1.3)',
  },

  title: {
    flexGrow: 1,
  },

  container: {
    display: 'flex',
  },
  paper: {
    margin: theme.spacing(1),
    paddingBottom: 50,
  },
  margin: {
    margin: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(0, 2),
  },
  logoPic: {
    maxWidth: '180px'
  },

  footer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(6)

  },

  logoFooter: {
    maxWidth: '10px'
  },

  text: {
    padding: theme.spacing(2, 2, 0),
  },

}));

function App() {

  const dispatch = useDispatch();

  const totalRecords = useSelector(state => state.total_db_records);
  const appDBStatus = useSelector(state => state.app_status_remoteDB);
  const appNetworkStatus = useSelector(state => state.app_status_online);
  const appAPIStatus = useSelector(state => state.app_status_api_online);
  const appLoadingStatus = useSelector(state => state.app_status_isLoading);
  const { authenticated } = useSelector(state => state.app_settings);
  const { screenSaver } = useSelector(state => state.app_settings);
  const { darkMode } = useSelector(state => state.app_settings);

  const testNetworkStatus = new Detector();

  const [mytheme, setMyTheme] = useState({
    palette: {
      type: darkMode ? "dark" : "light",
    }
  });

  const [APIonline, setAPIonline] = useState(false);
  const [online, setOnline] = useState(false);
  const [drawer, setdrawer] = useState(false);
  const [idle, setIdle] = useState(false);

  const onActive = () => {
    setIdle(false);
  }

  const onIdle = () => {
    setIdle(true);
  }

  const handleDarkModelToggle = (e) => {
    let newPaletteType = mytheme.palette.type === "light" ? "dark" : "light";

    setMyTheme({
      palette: {
        type: newPaletteType
      }
    });
    dispatch(Actions.updateSettingsDarkmode(!darkMode));
  }

  const handleScreensaverToggle = (e) => {
    dispatch(Actions.updateSettingsScreensaver(!screenSaver));
  }

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setdrawer(open);
  };

  window.addEventListener("online", () => {
    dispatch(Actions.updateStatusOnline(true));
  });

  window.addEventListener("offline", () => {
    dispatch(Actions.updateStatusOnline(false));
  });

  useEffect(() => {
    dispatch(Actions.updateStatusRemoteDb(online));
  }, [online]);

  useEffect(() => {
    dispatch(Actions.updateStatusAPI(APIonline));
  }, [APIonline]);

  useEffect(() => {
    dispatch(Actions.updateStatusOnline(testNetworkStatus.state.online));
    firebaseImpl.auth().onAuthStateChanged(user => {
      if (user) {
        dispatch(Actions.updateSettingsAuth(true));
      } else {
        dispatch(Actions.updateSettingsAuth(false));
      }
    });

    heartBeat();
    //fetchData();

    /*
    const liveDBUpdater = db.changes({
      since: 'now',
      live: true,
      include_docs: true,
    }).on('change', (change) => {

      if (!change.deleted) {
        if (change.changes[0].rev.split("-")[0] == 1) {
          console.log("NEW RECORD!", change.doc._id);
          axios.post('https://backend.now.btsdev.pt/webhook', { _id: change.doc._id }).then((result) => {

          }).catch(() => {

          });
        }
      }
    });*/

  }, ['']);

  const themeLight = createTheme(lightTheme);
  const themeDark = createTheme(darkTheme)

  const classes = useStyles();

  const heartBeat = () => {

    const fetchInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      cache: 'default',
    };

    const fetchRequest = new Request(`${COUCH_URL}/_up`, fetchInit);
    const fetchRequestAPI = new Request(`${API_ENDPOINT}/_up`, fetchInit);

    fetch(fetchRequestAPI).then((result) => {
      if (result.ok && !online) {
        setAPIonline(true);
      } else if (!result.ok) {
        setAPIonline(false);
      }
    }).catch(() => {
      setAPIonline(false);
    });

    fetch(fetchRequest).then((result) => {
      if (result.ok && !online) {
        setOnline(true);
      } else if (!result.ok) {
        setOnline(false);
      }
      setTimeout(heartBeat.bind(this), HEARTBEAT_DB_TIME);
    }).catch(() => {
      setOnline(false);
      setTimeout(heartBeat.bind(this), HEARTBEAT_DB_TIME);
    });
  };

  const handleClearCache = event => {
    dispatch(Actions.updateStatusIsLoading(true));
    event.preventDefault();
    try {

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (let registration of registrations) {
            registration.update()
          }
          dispatch(Actions.updateStatusIsLoading(false));
        })
      }

      /*
      navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          registrations.forEach(registration => {
            registration.unregister()
          })
          dispatch(Actions.updateStatusIsLoading(false));
        })*/
    } catch (error) {
      dispatch(Actions.updateStatusIsLoading(false));
    }
  };

  const handleLogOut = async event => {
    dispatch(Actions.updateStatusIsLoading(true));
    event.preventDefault();
    try {
      const user = await FirebaseService.logout();
      dispatch(Actions.updateStatusIsLoading(false));

    } catch (error) {
      dispatch(Actions.updateStatusIsLoading(false));
    }
  };

  const fetchData = () => {
    db.allDocs({
      include_docs: false,
      attachments: false,
    }).then(result => {
      const rows = result.rows;
      dispatch(Actions.updateTotalRecordsAction(rows.length));
      //dispatch(Actions.updateStatusIsLoading(false));
    }).catch((err) => {
      console.log(err);
      //dispatch(Actions.updateStatusIsLoading(false));
    });
  }

  const { t, i18n } = useTranslation();

  return (
    <Suspense fallback="loading"> 
      <MuiThemeProvider theme={darkMode ? createTheme(themeDark) : createTheme(themeLight)}>
        <SnackbarProvider autoHideDuration={5000} hideIconVariant={false}>
          <IdleMonitor
            activeClassName="user-is-working"
            idleClassName="UI-is-idle"
            onActive={onActive}
            onIdle={onIdle}
            timeout={IDLE_TIME}
          />

          {
            appLoadingStatus && (
              <Loader />
            )
          }

          {
            idle && screenSaver && (
              <div className={classes.videoContainer}>
                <ReactPlayer
                  url={sagiperVideoScreensaver}
                  className={classes.videoInner}
                  playing
                  loop
                  muted />)
          </div>
            )
          }

          <div className={classes.root}>

            <AppBar
              position="sticky"
              className={classes.topBar}
            >

              <Toolbar>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  onClick={toggleDrawer('left', !drawer)}
                  aria-label="menu">
                  <MenuIcon />
                </IconButton>

                <Typography variant="h6" className={classes.title}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <img
                      className={classes.logoPic}
                      src={PicLogo} 
                      alt="EXATRONIC APP"
                    />
                  </Grid>
                </Typography>

              </Toolbar>
            </AppBar>

            <Router>

              <Container component="main" className={classes.main}>
                {authenticated &&
                  <>
                    <Route path="/" exact render={(props) => <Front {...props} fetchData={fetchData} />} />
                    <Route path="/backoffice" exact component={Back} />
                  </>
                }
                {authenticated == false && authenticated != null && (
                  <Route component={Auth} />
                )}
              </Container>

              <Drawer
                open={drawer}
                onClose={toggleDrawer('left', false)}
              >

                <div
                  className={classes.drawerRoot}
                  role="presentation"
                  onClick={toggleDrawer('left', false)}
                  onKeyDown={toggleDrawer('left', false)}
                >

                  <CssBaseline />


                  {/*
                <ListItem
                  button
                  {...{ to: '/' }}
                  component={Link}
                >
                  <ListItemIcon><HomeIcon /></ListItemIcon>
                  <ListItemText primary={"Home"} />
                </ListItem>

                  {appDBStatus && totalRecords >= 1 && authenticated && (
                    <>
                      <List>
                        <ListItem
                          button
                          {...{ to: '/' }}
                          component={Link}
                        >
                          <ListItemIcon><ContactsIcon /></ListItemIcon>
                          <ListItemText primary={"Form"} />
                        </ListItem>


                        <ListItem
                          button
                          {...{ to: '/backoffice' }}
                          component={Link}
                        >
                          <ListItemIcon><StorageIcon /></ListItemIcon>
                          <ListItemText
                            disableTypography
                            primary={<Typography type="body2">Sync Queue</Typography>}
                          />

                          <Badge className={classes.margin} badgeContent={totalRecords} color="secondary">
                            <StorageIcon />
                          </Badge>

                        </ListItem>
                      </List>
                      <Divider />
                    </>
                  )}*/}

                  <List subheader={<ListSubheader><em>{t('SETTINGS')}</em></ListSubheader>}>

                    <ListItem button >

                      <FormControlLabel
                        control={
                          <Switch color="primary" checked={darkMode} onChange={handleDarkModelToggle} value={true} />
                        }
                        label={t('Night Mode')}
                      />

                    </ListItem>

                    <ListItem button >
                      <FormControlLabel
                        control={
                          <Switch color="primary" checked={screenSaver} onChange={handleScreensaverToggle} value={true} />
                        }
                        label="Screensaver"
                      />
                    </ListItem>

                  </List>

                  <List subheader={<ListSubheader><em>STATUS</em></ListSubheader>}>

                    {appNetworkStatus && (
                      <ListItem>
                        <ListItemText id="switch-list-label-wifi" primary="API" />
                        <ListItemSecondaryAction>

                          {appAPIStatus && (
                            <Badge className={classes.margin} color="secondary">
                              <CloudDoneIcon />
                            </Badge>
                          )}

                          {!appAPIStatus && (

                            <CloudOffIcon />

                          )}

                        </ListItemSecondaryAction>
                      </ListItem>
                    )}

                    {appNetworkStatus && (
                      <ListItem>
                        <ListItemText id="switch-list-label-wifi" primary={t('Sync DB')} />
                        <ListItemSecondaryAction>

                          {appDBStatus && (
                            <Badge className={classes.margin} color="secondary">
                              <CloudDoneIcon />
                            </Badge>
                          )}

                          {!appDBStatus && (

                            <CloudOffIcon />

                          )}

                        </ListItemSecondaryAction>
                      </ListItem>
                    )} 

                    {appNetworkStatus && (
                      <ListItem>
                        <ListItemText
                          id="switch-list-label-wifi"
                          primary={t('Network')}
                        />
                        <ListItemSecondaryAction>
                          <CloudDoneIcon className={classes.margin} />
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}

                    {!appNetworkStatus && (
                      <ListItem>
                        <ListItemText id="switch-list-label-wifi" primary={t('Network')} />
                        <ListItemSecondaryAction>
                          <CloudOffIcon className={classes.margin} />
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}

                  </List>

                  <Divider />

                  {authenticated &&
                    <>
                      <Tooltip title={`Clear Cache ${V}`}>
                        <IconButton
                          color="inherit"
                          onClick={handleClearCache}>
                          <AutorenewIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Logout">
                        <IconButton
                          color="inherit"
                          onClick={handleLogOut}>
                          <ExitToAppIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  }

                </div>
              </Drawer>
            </Router>

            <footer className={classes.footer}>
              <Container maxWidth="md">
                <Copyright classes={classes} />
              </Container>
            </footer>

          </div>


        </SnackbarProvider>
      </MuiThemeProvider>
    </Suspense >
  )
}

const Copyright = (props) => {
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {'© EXATRONIC '} {new Date().getFullYear() + " "} | Developed by Brandtellers
      </Typography>
    </>
  );
}

export default App;
