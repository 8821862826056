export const V = '190420220007';
export const COUCH_URL = 'https://db.btsdev.pt';

export const COUCH_DATABASE = 'exatronic'  
export const IDLE_TIME = 300000
export const HEARTBEAT_DB_TIME = 30000 
export const API_ENDPOINT = 'https://backend-exatronic.besttradeshowapp.com/api/v1';
//export const API_ENDPOINT = 'http://0.0.0.0:3000';
export const OCR_INSTALLED = true;

 
/*     
npm install
npm run-script build ou yarn build     
firebase deploy --only hosting
*/  