import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "SETTINGS": "SETTINGS",
            "SUBMIT FORM": "SUBMIT",
            "PHOTO": "PHOTO",
            "BUSINESS CARD": "BUSINESS CARD",
            "Name": "Name",
            "Company": "Company",
            "Address": "Address",
            "Role": "Role",
            "Phone": "Phone",
            "Country": "Country",
            "Notes": "Notes",
            "Product": "Product",

            "Service": "Service",

            

            "Network": "Network",
            "Sync DB": "Sync DB",
            "Night Mode": "Night Mode",

            "Tables": "Produto",
            "Tech. Info": "Produto",
            "Catalogs": "Produto",
            "Product Samples": "Produto",

            "Sign in": "Sign in",
            "Email Address": "Email Address",

            "Welcome": "Welcome",
            "Authentication failed": "Authentication failed",

            "OCR SUCCESSFULLY RUN IN": "OCR SUCCESSFULLY RUN IN",
            "AND DETECTED": "AND DETECTED",
            "SECONDS": "SECONDS",
            "OCR API IS BUSY... PLEASE TRY AGAIN LATER.": "OCR API IS BUSY... PLEASE TRY AGAIN LATER.",

            "Can pick multiple services": "Can pick multiple services",
            "Type of Communication": "Type of Communication",
            "By submiting this form, you agree with the storage and use of your data by EXATRONIC": "By submiting this form, you agree with the storage and use of your data by EXATRONIC",
            "I do accept to receive Marketing communications from EXATRONIC in the picked areas": "I do accept to receive Marketing communications from EXATRONIC in the picked areas",

            "THANK YOU!": "THANK YOU!",
            "You can assign": "You can assign",
            "to any of this fields.": "to any of this fields.",
            "Is it a phone number?": "Is it a phone number?",
            "Is it an E-mail?": "Is it an E-mail?",
            "What is it?": "What is it?"


        }
    },
    pt: {
        translation: {
            "SETTINGS": "DEFINIÇÕES",
            "SUBMIT FORM": "ENVIAR",
            "PHOTO": "FOTO",
            "BUSINESS CARD": "CARTÃO",
            "Name": "Nome",
            "Company": "Empresa",
            "Address": "Morada",
            "Role": "Cargo",
            "Phone": "Telefone",
            "Country": "País",
            "Notes": "Notas",
            "Product": "Produto",

            "Service": "Serviço",

            "Network": "Ligação à Internet",
            "Sync DB": "Base Dados",
            "Night Mode": "Modo Noturno",

            "Tables": "Tabelas",
            "Tech. Info": "Informação Técnica",
            "Catalogs": "Catálogos",
            "Product Samples": "Amostras",

            "Sign in": "Autenticar",
            "Email Address": "Endereço de e-mail",

            "Welcome": "Bem-vindo(a)",
            "Authentication failed": "Autenticação falhada",

            "OCR SUCCESSFULLY RUN IN": "OCR FEITO COM SUCESSO EM",
            "AND DETECTED": "E DETETOU",
            "SECONDS": "SEGUNDOS",
            "OCR API IS BUSY... PLEASE TRY AGAIN LATER.": "SERVIÇO DE OCR OCUPADO... PF. TENTE NOVAMENTE MAIS TARDE.",

            "Can pick multiple services": "Pode escolher múltiplos serviços", 
            "Type of Communication": "Tipo de Comunicação",
            "By submiting this form, you agree with the storage and use of your data by EXATRONIC": " Ao usar este formulário, você concorda com o armazenamento e o uso dos seus dados pela EXATRONIC. A Política de Privacidade será enviada por e-mail e pode ser consultada em https://exatronic.pt/politica-de-privacidade",
            "I do accept to receive Marketing communications from EXATRONIC in the picked areas": "Gostaria de receber comunicações de marketing da EXATRONIC",

            "THANK YOU!": "OBRIGADO!",
            "You can assign": "Pode associar",
            "to any of this fields.": "a qualquer um destes campos.",
            "Is it a phone number?": "É um número de telefone?",
            "Is it an E-mail?": "É um endereço de e-mail?",
            "What is it?": "O que é?"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en',
        lng: "pt",
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;