export default {
  direction: 'ltr',
  palette: {
    type: 'light',
    primary: {
      main: '#1289ac'
    },
    secondary: {
      main: '#051f4e'
    },

  },
};