import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyATuz43FOomNRKwfv8cpkpJmHyjUMCHh-A",
    authDomain: "exatronic-bts-app.firebaseapp.com",
    projectId: "exatronic-bts-app",
    storageBucket: "exatronic-bts-app.appspot.com",
    messagingSenderId: "537704476612",
    appId: "1:537704476612:web:30411d91e4399c40363e91"
};

export const firebaseImpl = firebase.initializeApp(firebaseConfig);
export const firebaseDatabase = firebase.database();