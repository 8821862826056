import PouchDB from 'pouchdb-browser';
import { COUCH_URL, COUCH_DATABASE } from "./constants";

export const db = new PouchDB('exatronic');
export const remoteDatabase = new PouchDB('https://mp:brandt3ll3rs@db.btsdev.pt/'+COUCH_DATABASE);

PouchDB.sync(db, remoteDatabase, {
    live: true,
    heartbeat: false,
    timeout: false,
    retry: true
}); 